// EbookPage.js
import React from "react";
import { useParams } from "react-router-dom";
import EbookLandingPageSection from "../../components/Custom/EbookLandingPageSection";
import workYourAskOffImage from '../../assets/WorkYourAskOff.jpg';
import sbEbookCoverGraphic from '../../assets/sbEbookCoverGraphic.jpeg';

const ebookConfigs = {
  workyouraskoff: {
    statusKey: "WorkYourAskOff",
    ebookCoverImage: workYourAskOffImage,
    ebookUrl: "https://firebasestorage.googleapis.com/v0/b/insurace-agency-trainin-9mw02g.appspot.com/o/resources%2FWork%20your%20ask%20off.pdf?alt=media&token=58d7fefd-21f4-45c8-9684-c4b9cbdac4a9",
    pdfAsset: "https://firebasestorage.googleapis.com/v0/b/insurace-agency-trainin-9mw02g.appspot.com/o/resources%2FWork%20your%20ask%20off.pdf?alt=media&token=58d7fefd-21f4-45c8-9684-c4b9cbdac4a9",
    leadFormZapTags: "has_website_login,ebook_lead_work_your_ask_off",
    leadFormAfterUrl: "/ebooks/workyouraskoff/success",
    headerText: "Thank you for filling out the form",
    descriptionText: "We appreciate your interest! View the PDF below and check your email for a copy.",
    ctaButtonText: "Create An Account",
    emailContent: `
      <p><strong>Thanks for requesting your copy of the Work Your Ask Off ebook!</strong></p>
      <p>This guide dives into the powerful art of asking the right questions to transform your sales conversations.</p>
      <p>Open the attached PDF and start putting these strategies into action today.</p>
      <p><strong>Warm regards,</strong><br/>The Insurance Agency Training Team</p>
    `,
    emailSubject: "Thank You For Your Interest in Our Product!",
    ebookHeader: "\"Telling isn't Selling\"",
    ebookIntro: "Most people talk too much and sell too little.  Use this e-book to master the art of asking questions.  What to say and what not to say.  When to say it and when not to.",
    ebookSubHeader: 'Good Decisions come from good information and it all starts with Working your "Ask" off.',
    ebookBenefits: [
      'This common sense and simple approach will allow you to master your craft, increase your sales and win in business.  Do not miss this important step in your sales IQ development.','Get your free e-book here, work with the Cowboy Coach, powered by Stairs and Buckets and his System of Influence.    Use this and  see how to work differently to get different results.',
    ],
    ebookConclusion: '',
    ebookCallToAction: ''
  },
  stairsandbuckets: {
    statusKey: "StairsAndBuckets",
    ebookCoverImage: sbEbookCoverGraphic,
    ebookUrl: "https://firebasestorage.googleapis.com/v0/b/insurace-agency-trainin-9mw02g.appspot.com/o/resources%2Febook.pdf?alt=media&token=c2d472b4-9204-4f0e-a819-fa481e68dde4",
    pdfAsset: "https://firebasestorage.googleapis.com/v0/b/insurace-agency-trainin-9mw02g.appspot.com/o/resources%2Febook.pdf?alt=media&token=c2d472b4-9204-4f0e-a819-fa481e68dde4",
    leadFormZapTags: "has_website_login,ebook_lead_stairs_and_buckets",
    leadFormAfterUrl: "/ebooks/stairsandbuckets/success",
    headerText: "Thank you for filling out the form",
    descriptionText: "We appreciate your interest! View the PDF below and check your email for your free ebook.",
    ctaButtonText: "Create An Account",
    emailContent: `
      <p>Thank you for signing up to receive the <em>Stairs & Buckets</em> ebook!</p>
      <p>If you're ready to take your insurance sales to the next level, this ebook is exactly what you need.</p>
      <p>Start reading today by opening the attached PDF.</p>
      <p><strong>Best regards,</strong><br/>Insurance Agency Training Team</p>
    `,
    emailSubject: "Thank You For Your Interest in Our Product!",
    ebookHeader: 'Get Your Free "Stairs & Buckets" Ebook Today!',
    ebookIntro: 'Want to make your insurance sales more effective and easier for clients to understand? We’re offering a free ebook that will show you how to use the "Stairs & Buckets" system—a straightforward and proven approach to explaining term and whole life insurance. This system helps you build trust with clients, close more sales, and create a consistent, repeatable sales process.',
    ebookSubHeader: 'Why Download This Free Ebook?',
    ebookBenefits: [
      'Learn how to clearly explain both term and whole life insurance so your clients understand the value of each type.',
      'Discover proven strategies to communicate the benefits of life insurance effectively.',
      'Use the "Stairs & Buckets" system to make your sales process impactful, easy to repeat, and effective for your entire team.'
    ],
    ebookConclusion: 'The "Stairs & Buckets" system is the key to making insurance sales simpler and more successful—not just for you, but for your whole team. This free ebook will guide you step-by-step on how to use this approach to transform your sales.',
    ebookCallToAction: 'Get your free ebook now and start transforming your sales process with the "Stairs & Buckets" system!'
  }
};

const EbookPage = () => {
  const { ebookSlug, status } = useParams();
  const config = ebookConfigs[ebookSlug];

  if (!config) {
    return <div>Invalid ebook requested.</div>;
  }

  return (
    <EbookLandingPageSection
      statusKey={config.statusKey}
      currentStatus={status} // should be "optin" or "success"
      ebookCoverImage={config.ebookCoverImage}
      ebookUrl={config.ebookUrl}
      leadFormZapTags={config.leadFormZapTags}
      leadFormAfterUrl={config.leadFormAfterUrl}
      headerText={config.headerText}
      descriptionText={config.descriptionText}
      ctaButtonText={config.ctaButtonText}
      emailContent={config.emailContent}
      emailSubject={config.emailSubject}
      ebookHeader={config.ebookHeader}
      ebookIntro={config.ebookIntro}
      ebookSubHeader={config.ebookSubHeader}
      ebookBenefits={config.ebookBenefits}
      ebookConclusion={config.ebookConclusion}
      ebookCallToAction={config.ebookCallToAction}
      pdfAsset={config.pdfAsset}
    />
  );
};

export default EbookPage;
