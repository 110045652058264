import React from "react"
import { useState, useEffect } from "react"
import {CakeIcon} from "@heroicons/react/24/solid";
import { createInvitation, useUserByEmail } from "../../util/db";
import { useAuth } from "../../util/auth";
import LoadingIcon from "../LoadingIcon";
import { apiRequest } from "../../util/util";
import { handleZapierExternal } from "../../util/zapier";


export default function MemberAddModal({email, team}) {
    const auth = useAuth()
    const [buttonDisabled, setButtonDisabled] = useState(false)

    const [success, setSuccess] = useState(false)
    const [successExt, setSuccessExt] = useState(false)
    const [alreadyMember, setAlreadyMember] = useState(false)


    const defaultAvatar = 'https://via.placeholder.com/150?text=Avatar';


    const {
        data: user,
        status: userStatus,
        error: userError
    } = useUserByEmail(email)

    async function postNewTagToZap(email,tags ) {
        const resp = await handleZapierExternal("postCustomer",
          {
          "email": email,
          "tags": tags
          }
        );
        console.log(resp)
      }

    async function sendInvitation() {
        setButtonDisabled(true)

        await createInvitation(
            {
                owner: user[0].id,
                fromEmail: auth.user.email,
                teamName: team.name,
                teamId: team.id,
                sender: auth.user.uid
            }
        )
        await postNewTagToZap( email, "team_member")
        setSuccess(true)
        setButtonDisabled(false)
    }

    async function sendExternalInvitation() {
        setButtonDisabled(true)

        await createInvitation(
            {
                owner: email,
                fromEmail: auth.user.email,
                teamName: team.name,
                teamId: team.id,
                secure: false,
                sender: auth.user.uid
            }
        )
        await postNewTagToZap( email, "team_member")

        await sendEmail()
        setSuccessExt(true)
        setButtonDisabled(false)
    }

    const sendEmail = async () =>  {
        setButtonDisabled(true)
        var message = `Hello, you have been invited to join a team on Insurance Agency Training. Please visit https://insuranceagencytraining.org/auth/signup to create an account. Please ensure you create an account with the email that this was sent to to ensure you receive the invitation. Once you have created an account click the bell in the top right to accept the request!`
        try {
          const data = {
                message: message,
                email: email,
                method: "Email",
                subject: "You Just Got Invited!",
                category: "invites"
            };
    
            const response = await apiRequest('send-message', 'POST', data);
            console.log('Response after sending code:', response);
        } catch (error) {
          console.error("Error creating code: ", error)
        }
        setButtonDisabled(false)
      }


      useEffect(() => {
        if (userStatus === "success" && user) {
          // Logic to perform once the user data is successfully fetched.
          if (user.length === 1) {
            if (team.members.includes(user[0].id)) {
                setAlreadyMember(true)
                console.log('member is already in team')
            } else {
                console.log("User found:", user[0]);
                sendInvitation()
            }
          } else {
            console.log("No user found for email:", email);
            sendExternalInvitation()
          }
        }
      }, [userStatus, user]);

    return (
        <>
        {(userStatus === "success" && user && user.length === 1)&&(
        <div className="flex flex-col items-center">
            {(!success)&&(
            <>
            {alreadyMember&&
                <>
                <h2 className=" text-center text-3xl font-bold pb-6">Hmm... They are already in this team.</h2>
                <p className="pb-4 text-md text-center text-gray-400">It appears that this user is already a member of this team. Try again with a different email.</p>
                </>
                }
            </>
            )}

        </div>
        )}
        {(success)&&(

        <div className="flex flex-col text-center space-y-4">
        <CakeIcon className='h-12 text-green-700'/>
        <h2 className='text-5xl font-semibold'>Invitation Sent Successfully!</h2>
        <p className='text-lg font-semithin'>Because {email} already has an account with us, they simply need to navigate to their dashboard to accept the invitation.</p>
        </div>
        )}

        {(successExt)&&(
        <div className="flex flex-col text-center space-y-4">
        <CakeIcon className='h-12 text-green-700'/>
        <h2 className='text-5xl font-semibold'>Invitation Sent Successfully!</h2>
        <p className='text-lg font-semithin'>An email has been sent inviting the user to the platform. Once they have signed in, they will see the invitation and can join your team!</p>
        </div>
        )}
        {(buttonDisabled)&&<>
        <LoadingIcon className = 'h-6'/>
        </>}
        </>

    )
  }